import { ICouponMetadata } from 'stripe_lib'

import {
	BlanketProducts,
	BundleProducts,
	ComforterProducts,
	FoundationProducts,
	HypericeProducts,
	NormaTecProducts,
	PillowProducts,
	ProtectorProducts,
	SheetProducts,
	SprayProducts,
	PodCoverProducts,
	MattressProducts,
	TheBaseProducts,
} from '../products/allProducts'
import { STRIPE_PRODUCTS } from './products'
import { VARIABLE_CURRENCY_MAP } from './scripts'

export interface ICouponData {
	id: string
	name: string
	metadata: ICouponMetadata
}

export const DISCOUNTS_MAP = {
	NEWYEAR: {
		GLOBAL_SALE_DISCOUNT_AMOUNT: 50,
		GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT: 100,
		GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT: 100,
		SLEEP_ESSENTIALS_BUNDLE_DISCOUNT: 50,
		MEMBER_UPGRADE_DISCOUNT: 300,
		ULTRA_MEMBER_UPGRADE_DISCOUNT: 400,
	},
	BETTERMEBETTERSLEEP: {
		GLOBAL_SALE_DISCOUNT_AMOUNT: 50,
		GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT: 100,
		GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT: 100,
		SLEEP_ESSENTIALS_BUNDLE_DISCOUNT: 50,
		MEMBER_UPGRADE_DISCOUNT: 300,
		ULTRA_MEMBER_UPGRADE_DISCOUNT: 400,
	},
}

export const GLOBAL_SALE_OLD_CODE = '' // not used right now
export const GLOBAL_SALE_DISCOUNT_CODE = 'BETTERMEBETTERSLEEP'

export const GLOBAL_SALE_DISCOUNT_AMOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].GLOBAL_SALE_DISCOUNT_AMOUNT
export const GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT
export const GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT

export const GLOBAL_AUD_BONUS = 0

export const SLEEP_ESSENTIALS_BUNDLE_DISCOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].SLEEP_ESSENTIALS_BUNDLE_DISCOUNT
export const SLEEP_ESSENTIALS_BUNDLE_COUPON = 'ACCESSORIESBUNDLE'

export const EMAIL_SMS_DISCOUNT_CODE = 'FIRSTSLEEP'
export const EMAIL_SMS_OLD_DISCOUNT_CODE = 'POD50'
export const EMAIL_SMS_INTERNAL_CODE = 'EMAIL50'
export const EMAIL_SMS_DISCOUNT_AMOUNT = 50

export const PARTNER_COVER_DISCOUNT = 200
export const PARTNER_ULTRA_COVER_DISCOUNT = 350
export const PARTNER_MATTRESS_DISCOUNT = 100
export const PARTNER_AUD_BONUS = 0

export const SPECIAL_COVER_STACKING_CODES = ['COVER25', 'COVER50', 'COVER75', 'COVER100', 'COVER125', 'COVER150', 'COVER200', 'COVER250']
export const SPECIAL_MATTRESS_STACKING_CODES = ['MATTRESS25', 'MATTRESS50', 'MATTRESS75', 'MATTRESS100', 'MATTRESS125', 'MATTRESS150', 'MATTRESS200', 'MATTRESS250']
export const SPECIAL_ULTRA_STACKING_CODES = ['ULTRA25', 'ULTRA50', 'ULTRA75', 'ULTRA100', 'ULTRA125', 'ULTRA150', 'ULTRA200', 'ULTRA250']
export const SPECIAL_PARTNER_ULTRA_STACKING_CODES = ['PULTRA25', 'PULTRA50', 'PULTRA75', 'PULTRA100', 'PULTRA125', 'PULTRA150', 'PULTRA200', 'PULTRA250']
export const SPECIAL_HONOR_STACKING_CODES = ['HONOR50', 'HONOR100', 'HONOR650', 'HONOR150', 'HONOR200', 'HONOR250', 'HONOR300']

export const GLOBAL_SALE_DISCOUNT_MIRRORS = [GLOBAL_SALE_DISCOUNT_CODE, 'EQUINOXSPA', 'REMEDY', 'ENDOFYEAR']

export const MEMBERS_DISCOUNT_CODE = 'MEMBERS'

export const MEMBER_UPGRADE_DISCOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].MEMBER_UPGRADE_DISCOUNT
export const MEMBER_UPGRADE_COUPON = 'MEMBER'

export const ULTRA_MEMBER_UPGRADE_DISCOUNT = DISCOUNTS_MAP[GLOBAL_SALE_DISCOUNT_CODE].ULTRA_MEMBER_UPGRADE_DISCOUNT
export const ULTRA_MEMBER_UPGRADE_COUPON = 'ULTRAMEMBER'

export const MEMBER_MATTRESS_DISCOUNT = 100
export const MEMBER_MATTRESS_COUPON = 'MEMBERUPGRADE'

export const MEMBER_THEBASE_DISCOUNT = 100
export const MEMBER_THEBASE_COUPON = 'MEMBERBASEUPGRADE'
export const MEMBER_THEBASE_DISCOUNT_MAP = {
	USD: MEMBER_THEBASE_DISCOUNT,
	GBP: MEMBER_THEBASE_DISCOUNT,
	EUR: MEMBER_THEBASE_DISCOUNT,
	CAD: MEMBER_THEBASE_DISCOUNT,
	AUD: MEMBER_THEBASE_DISCOUNT,
	SEK: VARIABLE_CURRENCY_MAP['SEK'][MEMBER_THEBASE_DISCOUNT],
	DKK: VARIABLE_CURRENCY_MAP['DKK'][MEMBER_THEBASE_DISCOUNT],
	AED: VARIABLE_CURRENCY_MAP['AED'][MEMBER_THEBASE_DISCOUNT],
}

export const THEBASE_DISCOUNT = 100
export const THEBASE_COUPON = 'BASEUPGRADE'

export const REFERRAL_COUPON = 'REFFRB'
export const REFERRAL_DISCOUNT_MAP = 'mattress50_cover200_ultra350'

export const ACCESSORIES_10_OFF_COUPON = 'ACCESSORIES10'
export const ACCESSORIES_20_OFF_COUPON = 'ACCESSORIES20'
export const ACCESSORIES_25_OFF_COUPON = 'ACCESSORIES25'
export const ACCESSORIES_30_OFF_COUPON = 'ACCESSORIES30'
export const ACCESSORIES_COUPONS = [ACCESSORIES_10_OFF_COUPON, ACCESSORIES_20_OFF_COUPON, ACCESSORIES_25_OFF_COUPON, ACCESSORIES_30_OFF_COUPON, SLEEP_ESSENTIALS_BUNDLE_COUPON, THEBASE_COUPON]

export const MEMBER_ACCESSORIES_10_OFF_COUPON = 'MEMBERACCESSORIES10'
export const MEMBER_ACCESSORIES_20_OFF_COUPON = 'MEMBERACCESSORIES20'
export const MEMBER_ACCESSORIES_25_OFF_COUPON = 'MEMBERACCESSORIES25'
export const MEMBER_ACCESSORIES_30_OFF_COUPON = 'MEMBERACCESSORIES30'
export const MEMBER_ACCESSORIES_COUPONS = [
	MEMBER_ACCESSORIES_10_OFF_COUPON,
	MEMBER_ACCESSORIES_20_OFF_COUPON,
	MEMBER_ACCESSORIES_25_OFF_COUPON,
	MEMBER_ACCESSORIES_30_OFF_COUPON,
	SLEEP_ESSENTIALS_BUNDLE_COUPON,
]

// these coupons will be stripped off the user provided list, they are generated by the system in apply_coupons.ts based on other coupons and conditions
export const USER_FORBIDDEN_COUPONS = [
	MEMBER_UPGRADE_COUPON,
	ULTRA_MEMBER_UPGRADE_COUPON,
	MEMBER_MATTRESS_COUPON,
	MEMBER_THEBASE_COUPON,
	THEBASE_COUPON,
	...SPECIAL_MATTRESS_STACKING_CODES,
	...SPECIAL_COVER_STACKING_CODES,
	...SPECIAL_ULTRA_STACKING_CODES,
	...SPECIAL_PARTNER_ULTRA_STACKING_CODES,
	...SPECIAL_HONOR_STACKING_CODES,
]

export const SYSTEM_COUPONS = [...USER_FORBIDDEN_COUPONS, EMAIL_SMS_INTERNAL_CODE, EMAIL_SMS_OLD_DISCOUNT_CODE]

export const NON_DISPLAYING_COUPONS = [...SPECIAL_COVER_STACKING_CODES, ...SPECIAL_ULTRA_STACKING_CODES, ...SPECIAL_PARTNER_ULTRA_STACKING_CODES]

// apply coupons function will use this map to split up an incoming coupon into multiple coupons
export const DISCOUNT_CODE_MAP = {
	[EMAIL_SMS_DISCOUNT_CODE]: [GLOBAL_SALE_DISCOUNT_CODE, EMAIL_SMS_INTERNAL_CODE],
	[EMAIL_SMS_OLD_DISCOUNT_CODE]: [EMAIL_SMS_INTERNAL_CODE],
	PUMP50: ['PUMPCLUB', 'PUMP50'],
	[MEMBERS_DISCOUNT_CODE]: [ULTRA_MEMBER_UPGRADE_COUPON, MEMBER_UPGRADE_COUPON, MEMBER_MATTRESS_COUPON, MEMBER_THEBASE_COUPON, ...MEMBER_ACCESSORIES_COUPONS],
}

export const COUPON_PRODUCTS_MAP = {
	[ACCESSORIES_10_OFF_COUPON]: [],
	[ACCESSORIES_20_OFF_COUPON]: [
		HypericeProducts.HypervoltPlus.id,
		HypericeProducts.VenomBack.id,
		NormaTecProducts.LegPulse2.id,
		SprayProducts.LavenderSpray.id,
		PillowProducts.CarbonAirPillow.id,
		PillowProducts.CarbonAirPillowKing.id,
		FoundationProducts.SimpleFoundation.id,
		BlanketProducts.BrandedGravity.id,
		ComforterProducts.AirLiteComforter.id,
		ProtectorProducts.PodProtector.id,
		SheetProducts.SheetSet.id,
		SheetProducts.SheetSetGray.id,
	],
	[ACCESSORIES_25_OFF_COUPON]: [],
	[ACCESSORIES_30_OFF_COUPON]: [],
	[MEMBER_ACCESSORIES_10_OFF_COUPON]: [],
	[MEMBER_ACCESSORIES_20_OFF_COUPON]: [],
	[MEMBER_ACCESSORIES_25_OFF_COUPON]: [
		HypericeProducts.HypervoltPlus.id,
		HypericeProducts.VenomBack.id,
		NormaTecProducts.LegPulse2.id,
		SprayProducts.LavenderSpray.id,
		ComforterProducts.AirLiteComforter.id,
		PillowProducts.CarbonAirPillow.id,
		PillowProducts.CarbonAirPillowKing.id,
		FoundationProducts.SimpleFoundation.id,
		BlanketProducts.BrandedGravity.id,
		ProtectorProducts.PodProtector.id,
		SheetProducts.SheetSet.id,
		SheetProducts.SheetSetGray.id,
	],
	[MEMBER_ACCESSORIES_30_OFF_COUPON]: [],
	[SLEEP_ESSENTIALS_BUNDLE_COUPON]: [BundleProducts.SleepEssentialBundle.id],
	[THEBASE_COUPON]: [TheBaseProducts.TheBase.id],
	[MEMBER_THEBASE_COUPON]: [TheBaseProducts.TheBase.id, TheBaseProducts.TheBaseNonMember.id],
	[MEMBER_MATTRESS_COUPON]: [MattressProducts.FiveLayerDiscounted.id, MattressProducts.ThreeLayer.id],
	[MEMBER_UPGRADE_COUPON]: [PodCoverProducts.Pod4.id, PodCoverProducts.Pod3CoverPerfect.id],
	[ULTRA_MEMBER_UPGRADE_COUPON]: [PodCoverProducts.Pod4Ultra.id],
}

export const couponIsApplicableToProduct = (couponId: string, productId: string, isVanity: boolean, product_collection: string) => {
	const id = couponId.toUpperCase()

	if (isVanity && product_collection === 'mattress') {
		return false
	}

	// we consider the coupon applicable if it's not in the map limited to specific products
	if (!COUPON_PRODUCTS_MAP[id]) return true
	return COUPON_PRODUCTS_MAP[id].length > 0 ? COUPON_PRODUCTS_MAP[id].includes(productId) : false
}

export const COUPON_DESCRIPTION = {
	[GLOBAL_SALE_DISCOUNT_CODE]: 'Better Me, Better Sleep Sale',
	NEWYEAR: 'Better Me, Better Sleep Sale',
	[ULTRA_MEMBER_UPGRADE_COUPON]: 'Members Exclusive',
	[THEBASE_COUPON]: 'Base Upgrade',
	[MEMBER_THEBASE_COUPON]: 'Members Exclusive',
	[MEMBER_UPGRADE_COUPON]: 'Members Exclusive',
	[MEMBER_MATTRESS_COUPON]: 'Members Exclusive',
	...MEMBER_ACCESSORIES_COUPONS.reduce((acc, it) => {
		acc[it] = 'Member Accessories Exclusive'
		return acc
	}, {} as Record<string, string>),
	...SPECIAL_MATTRESS_STACKING_CODES.reduce((acc, it) => {
		acc[it] = 'Mattress'
		return acc
	}, {} as Record<string, string>),
	[SLEEP_ESSENTIALS_BUNDLE_COUPON]: 'Bundle',
}

export const COUPON_VARIANT_MAPPING: Record<string, Record<string, string>> = {
	HONOR: {
		[STRIPE_PRODUCTS.pod_4__queen]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4__king]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4__cali_king]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4_ultra__queen]: 'HONOR650',
		[STRIPE_PRODUCTS.pod_4_ultra__king]: 'HONOR650',
		[STRIPE_PRODUCTS.pod_4_ultra__cali_king]: 'HONOR650',
		[STRIPE_PRODUCTS.pod_4__ca_full]: 'HONOR50',
		[STRIPE_PRODUCTS.pod_4__ca_queen]: 'HONOR150',
		[STRIPE_PRODUCTS.pod_4__ca_king]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4_ultra__ca_queen]: 'HONOR150',
		[STRIPE_PRODUCTS.pod_4_ultra__ca_king]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4__eu_super_king]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4__eu_emperor]: 'HONOR200',
		[STRIPE_PRODUCTS.pod_4_ultra__eu_super_king]: 'HONOR150',
		[STRIPE_PRODUCTS.pod_4_ultra__eu_emperor]: 'HONOR250',
		[STRIPE_PRODUCTS.pod_4__uk_emperor]: 'HONOR100',
		[STRIPE_PRODUCTS.pod_4_ultra__uk_super_king]: 'HONOR150',
		[STRIPE_PRODUCTS.pod_4_ultra__uk_emperor]: 'HONOR250',
		[STRIPE_PRODUCTS.pod_4__au_queen]: 'HONOR300',
		[STRIPE_PRODUCTS.pod_4__au_king]: 'HONOR300',
		[STRIPE_PRODUCTS.pod_4_ultra__au_queen]: 'HONOR250',
		[STRIPE_PRODUCTS.pod_4_ultra__au_king]: 'HONOR50',
	},
}

export const PARTNER_COUPONS = [
	'ABBEY',
	'AEZ',
	'ALFALFA',
	'ALLTHEHACKS',
	'AM2PM',
	'ASKTHEDENTIST',
	'AUSTIN',
	'AUTHENTECH',
	'BALANCINGLAURA',
	'BANANALAND',
	'BEN',
	'BIOHACKERBABES',
	'BLAKEH',
	'BLUEPRINT',
	'BMAC',
	'BOSS',
	'BRANDON',
	'BRUNO',
	'CARNIVOREMD',
	'CASEY',
	'CCHUNGY',
	'CFKATE',
	'CHERNOW',
	'CODYKO',
	'COLTON',
	'DAILYSTOIC',
	'DALTON',
	'DANGERCLOSE',
	'DARKHORSE',
	'DARUSTRONG',
	'DATKETOLADY',
	'DAVE',
	'DEEP',
	'DIGEST',
	'DOPE',
	'DRBECKY',
	'DRDANDPT',
	'DRLYON',
	'DROID',
	'DRTYNA',
	'EF200',
	'ELISSA',
	'ENDORPHINS',
	'EXCELLENCE',
	'FMM',
	'FOUNDERS',
	'FREESTYLE',
	'GOOCH',
	'HAMZA',
	'HOEFLINGER',
	'HOLT',
	'HUBERMAN',
	'IJUSTINE',
	'IMAN',
	'IMPOSSIBLE',
	'JAMIE',
	'JANET',
	'JENLAUREN',
	'JERRYRIG',
	'JOCKERS ',
	'JORDAN',
	'JORGE',
	'JULIANDOREY',
	'JUSTIN',
	'KAIT',
	'KALLAWAY',
	'KATIE',
	'KELTIE',
	'KUMMER',
	'KYLEGOTCAMERA',
	'LAURATRY',
	'LAURENKAY',
	'LEARNWITHTRAVIS',
	'LEGALAF',
	'LEWIS',
	'LEX',
	'LIT',
	'LMG',
	'LOGAN50',
	'LOUIS',
	'LOUISA',
	'LUCAS',
	'MARK',
	'MATT',
	'MAUS',
	'MAX',
	'MIKE',
	'MIKE50',
	'MINDPUMP',
	'MKBHD',
	'MMA50',
	'MODERNWISDOM',
	'MOONSHOTS',
	'MORENEWS',
	'MORGAN',
	'MOVES',
	'MOVEWELL',
	'MPMD',
	'NASJAQ',
	'NICOLET',
	'NOAH',
	'NOBREAD',
	'NVDM',
	'PAKMAN',
	'POMP',
	'POWERATHLETE',
	'POWERPROJECT',
	'PROJECTANDREW',
	'PURPOSE',
	'REYSU',
	'ROGAN',
	'SADIK',
	'SAMI',
	'SETH',
	'SEVAN',
	'SHERVIN',
	'SIIMLAND',
	'SMARTLESS',
	'SOMETHERAPIST',
	'SOPHIE',
	'SPAGS',
	'STEVE',
	'STEVEN',
	'STOLTMAN',
	'STRAVA15',
	'SWEATS',
	'TECHLINKED',
	'THE5KRUNNER',
	'THEMOVE',
	'THESTOUT79',
	'THIO',
	'TIM',
	'TOMMCGEEFIT',
	'TQS',
	'TRAVIS',
	'TRENDIFIER',
	'TRINA',
	'TROY',
	'TWIST',
	'TWIT',
	'UNBOXTHERAPY',
	'VANESSA',
	'VICKY',
	'WHATSGABYCOOKIN',
	'YE',
	'ZONE',
	'JTPR2024',
	'FITTEST2024',
	'AP2024',
	'EIGHTFRIENDS2024',
	'EQUINOX2024',
	'HIGHERDOSE2024',
	'PLUNGE2024',
	'HYPERICE2024',
	'LEVELS2024',
	'BEAM2024',
	'INSIDETRACKER2024',
	'ROOTINE2024',
	'PRENUVO2024',
	'HYDROW2024',
	'AGELESS2024',
	'FUTURE2024',
	'AG2024',
	'TALLY2024',
	'APL2024',
	'EFCYCLING2024',
	'STRIPE2024',
	'BOOKFACE2024',
	'PUMP50',
]
