import { CSSProperties, FC } from 'react'
import { ThumbnailsProps } from 'components/ImageCarousel/ImageCarousel.types'
import styles from './Thumbnails.module.scss'
import { Button } from 'components/Phantom/Button'
import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import { ImageProps, VideoProps } from 'components/_utils/utilityTypes'
import { Img } from 'components/basic/Img'
import { Icon } from 'components/Phantom/Icon'

export const Thumbnails: FC<ThumbnailsProps> = (props) => {
	const { items, currentIndex, setCurrentIndex, useDots } = props
	if (items.length <= 1) return null

	return (
		<ul
			className={styles.thumbnails}
			data-use-dots={useDots}
			style={{ '--num-columns': items.length } as CSSProperties}
		>
			{items.map((item, index) => (
				<li
					key={index}
					className={styles.thumbnail}
					data-highlight={index === currentIndex}
				>
					<Button.Empty
						id={`thumbnail-${index}`}
						ariaLabel={`Go to slide ${index + 1}`}
						className={styles.thumbnail_button}
						onClick={() => setCurrentIndex(index)}
					>
						<div className={styles.thumbnail_dot} />
						<ThumbnailRendered
							{...item}
							index={index}
							active={currentIndex === index}
						/>
					</Button.Empty>
				</li>
			))}
		</ul>
	)
}

const ThumbnailRendered: FC<StackItemProps & { index: number; active: boolean }> = (props) => {
	const { type, data, index, active } = props

	switch (type) {
		case 'image': {
			const _data = data as ImageProps
			return (
				<Img
					src={_data.src}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={_data.objectFit ?? 'cover'}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.thumbnail_video_container}>
					<Icon
						name={active ? 'PauseDark' : 'PlayDark'}
						color={'white'}
					/>
					<Img
						src={_data.poster}
						dprHeight={300}
						alt={`Thumbnail for slide ${index + 1}`}
						className={styles.thumbnail_image}
						objectFit={'cover'}
					/>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<Img
					src={_data.poster}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={'cover'}
				/>
			)
		}
	}
}
