import { ProductCore } from './types'
import * as productDescriptions from './productDescriptions'
import { STRIPE_PRODUCTS } from '../stripe_lib/products'

export const PodCoverProducts = {
	Pod2ProCoverStandard: {
		handle: 'pod-2-pro-cover',
		name: 'Pod 2 Cover',
		id: 'pod_2_cover',
		description: productDescriptions.podPro2CoverDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 39958322741336,
				id: STRIPE_PRODUCTS.pod_2_cover__full,
			},
			queen: {
				name: 'Queen',
				// id: 39958322774104,
				id: STRIPE_PRODUCTS.pod_2_cover__queen,
			},
			king: {
				name: 'King',
				// id: 39958322806872,
				id: STRIPE_PRODUCTS.pod_2_cover__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 39958322839640,
				id: STRIPE_PRODUCTS.pod_2_cover__cali_king,
			},
			eudouble: {
				name: 'EU Double',
				// id: 39958322872408,
				id: STRIPE_PRODUCTS.pod_2_cover__eu_double,
			},
			eulargedouble: {
				name: 'EU Large Double',
				// id: 39958322905176,
				id: STRIPE_PRODUCTS.pod_2_cover__eu_large_double,
			},
			eusuperking: {
				name: 'EU Super King',
				// id: 39958322937944,
				id: STRIPE_PRODUCTS.pod_2_cover__eu_super_king,
			},
			ukdouble: {
				name: 'UK Double',
				// id: 39958322970712,
				id: STRIPE_PRODUCTS.pod_2_cover__uk_double,
			},
			ukking: {
				name: 'UK King',
				// id: 39958323003480,
				id: STRIPE_PRODUCTS.pod_2_cover__uk_king,
			},
			uksuperking: {
				name: 'UK Super King',
				// id: 39958323036248,
				id: STRIPE_PRODUCTS.pod_2_cover__uk_super_king,
			},
			audouble: {
				name: 'AU Double',
				// id: 39958323069016,
				id: STRIPE_PRODUCTS.pod_2_cover__au_double,
			},
			auqueen: {
				name: 'AU Queen',
				// id: 39958323101784,
				id: STRIPE_PRODUCTS.pod_2_cover__au_queen,
			},
			auking: {
				name: 'AU King',
				// id: 39958323134552,
				id: STRIPE_PRODUCTS.pod_2_cover__au_king,
			},
			cafull: {
				name: 'CA Full',
				// id: 40118736060504,
				id: STRIPE_PRODUCTS.pod_2_cover__ca_full,
			},
			caqueen: {
				name: 'CA Queen',
				// id: 40118736093272,
				id: STRIPE_PRODUCTS.pod_2_cover__ca_queen,
			},
			caking: {
				name: 'CA King',
				// id: 40118736158808,
				id: STRIPE_PRODUCTS.pod_2_cover__ca_king,
			},
			sedouble: {
				name: 'SE Double',
				// id: 40216811044952,
				id: STRIPE_PRODUCTS.pod_2_cover__se_double,
			},
			seking: {
				name: 'SE Large Double',
				// id: 40216811077720,
				id: STRIPE_PRODUCTS.pod_2_cover__se_large_double,
			},
			sesuperking: {
				name: 'SE Super King',
				// id: 40216811110488,
				id: STRIPE_PRODUCTS.pod_2_cover__se_super_king,
			},
			dkdouble: {
				name: 'DK Double',
				// id: 40216811143256,
				id: STRIPE_PRODUCTS.pod_2_cover__dk_double,
			},
			dkking: {
				name: 'DK Large Double',
				// id: 40216811176024,
				id: STRIPE_PRODUCTS.pod_2_cover__dk_large_double,
			},
			dksuperking: {
				name: 'DK Super King',
				// id: 40216811208792,
				id: STRIPE_PRODUCTS.pod_2_cover__dk_super_king,
			},
		},
	} as ProductCore,
	Pod3CoverStandard: {
		handle: 'pod-pro-cover-3-standard',
		name: 'Pod 3',
		shopTitleBadge: 'NEW',
		id: 'pod_3_cover',
		description: productDescriptions.podPro3CoverStandardDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 39952297394264,
				id: STRIPE_PRODUCTS.pod_3_cover__full,
			},
			queen: {
				name: 'Queen',
				// id: 39952297427032,
				id: STRIPE_PRODUCTS.pod_3_cover__queen,
			},
			king: {
				name: 'King',
				// id: 39952297459800,
				id: STRIPE_PRODUCTS.pod_3_cover__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 39952297492568,
				id: STRIPE_PRODUCTS.pod_3_cover__cali_king,
			},
			eudouble: {
				name: 'EU Double',
				// id: 39952297656408,
				id: STRIPE_PRODUCTS.pod_3_cover__eu_double,
			},
			eulargedouble: {
				name: 'EU Large Double',
				// id: 39952297689176,
				id: STRIPE_PRODUCTS.pod_3_cover__eu_large_double,
			},
			eusuperking: {
				name: 'EU Super King',
				// id: 39952297721944,
				id: STRIPE_PRODUCTS.pod_3_cover__eu_super_king,
			},
			ukdouble: {
				name: 'UK Double',
				// id: 39952297754712,
				id: STRIPE_PRODUCTS.pod_3_cover__uk_double,
			},
			ukking: {
				name: 'UK King',
				// id: 39952297787480,
				id: STRIPE_PRODUCTS.pod_3_cover__uk_king,
			},
			uksuperking: {
				name: 'UK Super King',
				// id: 39952297820248,
				id: STRIPE_PRODUCTS.pod_3_cover__uk_super_king,
			},
			audouble: {
				name: 'AU Double',
				// id: 39952297853016,
				id: STRIPE_PRODUCTS.pod_3_cover__au_double,
			},
			auqueen: {
				name: 'AU Queen',
				// id: 39952297885784,
				id: STRIPE_PRODUCTS.pod_3_cover__au_queen,
			},
			auking: {
				name: 'AU King',
				// id: 39952297918552,
				id: STRIPE_PRODUCTS.pod_3_cover__au_king,
			},
			cafull: {
				name: 'CA Full',
				// id: 40118734454872,
				id: STRIPE_PRODUCTS.pod_3_cover__ca_full,
			},
			caqueen: {
				name: 'CA Queen',
				// id: 40118735143000,
				id: STRIPE_PRODUCTS.pod_3_cover__ca_queen,
			},
			caking: {
				name: 'CA King',
				// id: 40118735175768,
				id: STRIPE_PRODUCTS.pod_3_cover__ca_king,
			},
			sedouble: {
				name: 'SE Double',
				// id: 40216812879960,
				id: STRIPE_PRODUCTS.pod_3_cover__se_double,
			},
			seking: {
				name: 'SE Large Double',
				// id: 40216812912728,
				id: STRIPE_PRODUCTS.pod_3_cover__se_large_double,
			},
			sesuperking: {
				name: 'SE Super King',
				// id: 40216812945496,
				id: STRIPE_PRODUCTS.pod_3_cover__se_super_king,
			},
			dkdouble: {
				name: 'DK Double',
				// id: 40216812978264,
				id: STRIPE_PRODUCTS.pod_3_cover__dk_double,
			},
			dkking: {
				name: 'DK Large Double',
				// id: 40216813011032,
				id: STRIPE_PRODUCTS.pod_3_cover__dk_large_double,
			},
			dksuperking: {
				name: 'DK Super King',
				// id: 40216813043800,
				id: STRIPE_PRODUCTS.pod_3_cover__dk_super_king,
			},
		},
	} as ProductCore,
	Pod3CoverPerfect: {
		handle: 'pod-pro-cover-3-perfect',
		name: 'Pod 3',
		shopTitleBadge: 'NEW',
		id: 'pod_3_cover_perfect',
		description: productDescriptions.podPro3CoverPerfectFitDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 39952298147928,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__full,
			},
			queen: {
				name: 'Queen',
				// id: 39952298180696,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__queen,
			},
			king: {
				name: 'King',
				// id: 39952298213464,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 39952298246232,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__cali_king,
			},
			eudouble: {
				name: 'EU Double',
				// id: 39952298279000,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__eu_double,
			},
			eulargedouble: {
				name: 'EU Large Double',
				// id: 39952298311768,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__eu_large_double,
			},
			eusuperking: {
				name: 'EU Super King',
				// id: 39952298344536,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__eu_super_king,
			},
			ukdouble: {
				name: 'UK Double',
				// id: 39952298377304,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__uk_double,
			},
			ukking: {
				name: 'UK King',
				// id: 39952298410072,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__uk_king,
			},
			uksuperking: {
				name: 'UK Super King',
				// id: 39952298442840,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__uk_super_king,
			},
			audouble: {
				name: 'AU Double',
				// id: 39952298475608,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__au_double,
			},
			auqueen: {
				name: 'AU Queen',
				// id: 39952298508376,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__au_queen,
			},
			auking: {
				name: 'AU King',
				// id: 39952298541144,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__au_king,
			},
			cafull: {
				name: 'CA Full',
				// id: 40118734159960,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ca_full,
			},
			caqueen: {
				name: 'CA Queen',
				// id: 40118734192728,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ca_queen,
			},
			caking: {
				name: 'CA King',
				// id: 40118734356568,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ca_king,
			},
			sedouble: {
				name: 'SE Double',
				// id: 40216779161688,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__se_double,
			},
			seking: {
				name: 'SE Large Double',
				// id: 40216779194456,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__se_large_double,
			},
			sesuperking: {
				name: 'SE Super King',
				// id: 40216779227224,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__se_super_king,
			},
			dkdouble: {
				name: 'DK Double',
				// id: 40216779259992,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__dk_double,
			},
			dkking: {
				name: 'DK Large Double',
				// id: 40216779292760,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__dk_large_double,
			},
			dksuperking: {
				name: 'DK Super King',
				// id: 40216779325528,
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__dk_super_king,
			},
			aequeen: {
				name: 'AE Queen',
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ae_queen,
			},
			aeking: {
				name: 'AE King',
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ae_king,
			},
			aeemperor: {
				name: 'AE Emperor',
				id: STRIPE_PRODUCTS.pod_3_cover_perfectfit__ae_emperor,
			},
		},
	} as ProductCore,
	Pod4: {
		handle: 'pod-4',
		name: 'Pod 4',
		shopTitleBadge: 'NEW',
		id: 'pod_4',
		description: productDescriptions.podPro3CoverPerfectFitDescription,
		variants: {
			full: {
				name: 'Full',
				id: STRIPE_PRODUCTS.pod_4__full,
			},
			queen: {
				name: 'Queen',
				id: STRIPE_PRODUCTS.pod_4__queen,
			},
			king: {
				name: 'King',
				id: STRIPE_PRODUCTS.pod_4__king,
			},
			caliking: {
				name: 'Cali King',
				id: STRIPE_PRODUCTS.pod_4__cali_king,
			},
			eudouble: {
				name: 'EU Double',
				id: STRIPE_PRODUCTS.pod_4__eu_double,
			},
			eulargedouble: {
				name: 'EU Large Double',
				id: STRIPE_PRODUCTS.pod_4__eu_large_double,
			},
			eusuperking: {
				name: 'EU Super King',
				id: STRIPE_PRODUCTS.pod_4__eu_super_king,
			},
			euemperor: {
				name: 'EU Emperor',
				id: STRIPE_PRODUCTS.pod_4__eu_emperor,
			},
			ukdouble: {
				name: 'UK Double',
				id: STRIPE_PRODUCTS.pod_4__uk_double,
			},
			ukking: {
				name: 'UK King',
				id: STRIPE_PRODUCTS.pod_4__uk_king,
			},
			uksuperking: {
				name: 'UK Super King',
				id: STRIPE_PRODUCTS.pod_4__uk_super_king,
			},
			ukemperor: {
				name: 'UK Emperor',
				id: STRIPE_PRODUCTS.pod_4__uk_emperor,
			},
			audouble: {
				name: 'AU Double',
				id: STRIPE_PRODUCTS.pod_4__au_double,
			},
			auqueen: {
				name: 'AU Queen',
				id: STRIPE_PRODUCTS.pod_4__au_queen,
			},
			auking: {
				name: 'AU King',
				id: STRIPE_PRODUCTS.pod_4__au_king,
			},
			cafull: {
				name: 'CA Full',
				id: STRIPE_PRODUCTS.pod_4__ca_full,
			},
			caqueen: {
				name: 'CA Queen',
				id: STRIPE_PRODUCTS.pod_4__ca_queen,
			},
			caking: {
				name: 'CA King',
				id: STRIPE_PRODUCTS.pod_4__ca_king,
			},
			sedouble: {
				name: 'SE Double',
				id: STRIPE_PRODUCTS.pod_4__se_double,
			},
			seking: {
				name: 'SE Large Double',
				id: STRIPE_PRODUCTS.pod_4__se_large_double,
			},
			sesuperking: {
				name: 'SE Super King',
				id: STRIPE_PRODUCTS.pod_4__se_super_king,
			},
			seemperor: {
				name: 'SE Emperor',
				id: STRIPE_PRODUCTS.pod_4__se_emperor,
			},
			dkdouble: {
				name: 'DK Double',
				id: STRIPE_PRODUCTS.pod_4__dk_double,
			},
			dkking: {
				name: 'DK Large Double',
				id: STRIPE_PRODUCTS.pod_4__dk_large_double,
			},
			dksuperking: {
				name: 'DK Super King',
				id: STRIPE_PRODUCTS.pod_4__dk_super_king,
			},
			dkemperor: {
				name: 'DK Emperor',
				id: STRIPE_PRODUCTS.pod_4__dk_emperor,
			},
			aequeen: {
				name: 'AE Queen',
				id: STRIPE_PRODUCTS.pod_4__ae_queen,
			},
			aeking: {
				name: 'AE King',
				id: STRIPE_PRODUCTS.pod_4__ae_king,
			},
			aeemperor: {
				name: 'AE Emperor',
				id: STRIPE_PRODUCTS.pod_4__ae_emperor,
			},
		},
	} as ProductCore,
	Pod4Ultra: {
		handle: 'pod-4-ultra',
		name: 'Pod 4 Ultra',
		shopTitleBadge: 'NEW',
		id: 'pod_4_ultra',
		description: productDescriptions.podPro3CoverPerfectFitDescription,
		variants: {
			full: {
				name: 'Full',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__full,
			},
			queen: {
				name: 'Queen',
				id: STRIPE_PRODUCTS.pod_4_ultra__queen,
			},
			king: {
				name: 'King',
				id: STRIPE_PRODUCTS.pod_4_ultra__king,
			},
			caliking: {
				name: 'Cali King',
				id: STRIPE_PRODUCTS.pod_4_ultra__cali_king,
			},
			eudouble: {
				name: 'EU Double',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__eu_double,
			},
			eulargedouble: {
				name: 'EU Large Double',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__eu_large_double,
			},
			eusuperking: {
				name: 'EU Super King',
				id: STRIPE_PRODUCTS.pod_4_ultra__eu_super_king,
			},
			euemperor: {
				name: 'EU Emperor',
				id: STRIPE_PRODUCTS.pod_4_ultra__eu_emperor,
			},
			ukdouble: {
				name: 'UK Double',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__uk_double,
			},
			ukking: {
				name: 'UK King',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__uk_king,
			},
			uksuperking: {
				name: 'UK Super King',
				id: STRIPE_PRODUCTS.pod_4_ultra__uk_super_king,
			},
			ukemperor: {
				name: 'UK Emperor',
				id: STRIPE_PRODUCTS.pod_4_ultra__uk_emperor,
			},
			audouble: {
				name: 'AU Double',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__au_double,
			},
			auqueen: {
				name: 'AU Queen',
				id: STRIPE_PRODUCTS.pod_4_ultra__au_queen,
			},
			auking: {
				name: 'AU King',
				id: STRIPE_PRODUCTS.pod_4_ultra__au_king,
			},
			cafull: {
				name: 'CA Full',
				id: undefined, // STRIPE_PRODUCTS.pod_4_ultra__ca_full,
			},
			caqueen: {
				name: 'CA Queen',
				id: STRIPE_PRODUCTS.pod_4_ultra__ca_queen,
			},
			caking: {
				name: 'CA King',
				id: STRIPE_PRODUCTS.pod_4_ultra__ca_king,
			},
			sedouble: {
				name: 'SE Double',
				id: STRIPE_PRODUCTS.pod_4_ultra__se_double,
			},
			seking: {
				name: 'SE Large Double',
				id: STRIPE_PRODUCTS.pod_4_ultra__se_large_double,
			},
			sesuperking: {
				name: 'SE Super King',
				id: STRIPE_PRODUCTS.pod_4_ultra__se_super_king,
			},
			seemperor: {
				name: 'SE Emperor',
				id: STRIPE_PRODUCTS.pod_4_ultra__se_emperor,
			},
			dkdouble: {
				name: 'DK Double',
				id: STRIPE_PRODUCTS.pod_4_ultra__dk_double,
			},
			dkking: {
				name: 'DK Large Double',
				id: STRIPE_PRODUCTS.pod_4_ultra__dk_large_double,
			},
			dksuperking: {
				name: 'DK Super King',
				id: STRIPE_PRODUCTS.pod_4_ultra__dk_super_king,
			},
			dkemperor: {
				name: 'DK Emperor',
				id: STRIPE_PRODUCTS.pod_4_ultra__dk_emperor,
			},
			aeking: {
				name: 'AE King',
				id: STRIPE_PRODUCTS.pod_4_ultra__ae_king,
			},
			aeemperor: {
				name: 'AE Emperor',
				id: STRIPE_PRODUCTS.pod_4_ultra__ae_emperor,
			},
		},
	} as ProductCore,
}

export const Pod4Accessories = {
	Pod4UltraLegKit: {
		handle: 'pod-4-ultra-leg-kit',
		name: 'Leg Kit',
		id: 'pod_4_ultra_leg_kit',
		description: ['Pod 4 Ultra Leg Kit Description'],
		variants: {
			standard: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod_4_ultra_leg_kit__standard,
			},
		},
	} as ProductCore,
}

export const ComforterProducts = {
	AirLiteComforter: {
		handle: 'air-lite-comforter',
		name: 'Air Lite Comforter',
		id: 'air_lite_comforter',
		description: productDescriptions.airLiteComforterDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 32841666592856,
				id: STRIPE_PRODUCTS.air_lite_comforter__full,
			},
			queen: {
				name: 'Queen',
				// id: 32841666625624,
				id: STRIPE_PRODUCTS.air_lite_comforter__queen,
			},
			king: {
				name: 'King',
				// id: 32841666658392,
				id: STRIPE_PRODUCTS.air_lite_comforter__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 32841666691160,
				id: STRIPE_PRODUCTS.air_lite_comforter__cali_king,
			},
		},
	} as ProductCore,
}

export const ProtectorProducts = {
	PodProtector: {
		handle: 'the-pod-protector',
		name: 'Pod Protector',
		id: 'pod_protector',
		description: productDescriptions.podProtectorDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 33044653768792,
				id: STRIPE_PRODUCTS.pod_protector__full,
			},
			queen: {
				name: 'Queen',
				// id: 33044653801560,
				id: STRIPE_PRODUCTS.pod_protector__queen,
			},
			king: {
				name: 'King',
				// id: 33044653834328,
				id: STRIPE_PRODUCTS.pod_protector__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 33044653867096,
				id: STRIPE_PRODUCTS.pod_protector__cali_king,
			},
		},
	} as ProductCore,
}
export const TheBaseProducts = {
	TheBase: {
		handle: 'the-base',
		name: 'The Base',
		id: 'pod_4_base_upgrade',
		description: productDescriptions.theBaseDescription,
		variants: {
			queen: {
				name: 'Queen',
				// id: 39958322774104,
				id: STRIPE_PRODUCTS.pod_4_base__queen_upgrade,
			},
			king: {
				name: 'King',
				// id: 39958322806872,
				id: STRIPE_PRODUCTS.pod_4_base__king_upgrade,
			},
			caliking: {
				name: 'Cali King',
				// id: 39958322839640,
				id: STRIPE_PRODUCTS.pod_4_base__caliking_upgrade,
			},
			eudouble: {
				name: 'EU Double',
				// id: 39958322872408,
				id: STRIPE_PRODUCTS.pod_4_base__eu_double_upgrade,
			},
			euemperor: {
				name: 'EU Emperor',
				// id: 39958322872408,
				id: STRIPE_PRODUCTS.pod_4_base__eu_emperor_upgrade,
			},
			eulargedouble: {
				name: 'EU Large Double',
				// id: 39958322905176,
				id: STRIPE_PRODUCTS.pod_4_base__eu_large_double_upgrade,
			},
			eusuperking: {
				name: 'EU Super King',
				// id: 39958322937944,
				id: STRIPE_PRODUCTS.pod_4_base__eu_super_king_upgrade,
			},
			ukdouble: {
				name: 'UK Double',
				// id: 39958322970712,
				id: STRIPE_PRODUCTS.pod_4_base__uk_double_upgrade,
			},
			ukking: {
				name: 'UK King',
				// id: 39958323003480,
				id: STRIPE_PRODUCTS.pod_4_base__uk_king_upgrade,
			},
			uksuperking: {
				name: 'UK Super King',
				// id: 39958323036248,
				id: STRIPE_PRODUCTS.pod_4_base__uk_super_king_upgrade,
			},
			ukemperor: {
				name: 'UK Emperor',
				// id: 39958323036248,
				id: STRIPE_PRODUCTS.pod_4_base__uk_emperor_upgrade,
			},
			auqueen: {
				name: 'AU Queen',
				// id: 39958323101784,
				id: STRIPE_PRODUCTS.pod_4_base__au_queen_upgrade,
			},
			auking: {
				name: 'AU King',
				// id: 39958323134552,
				id: STRIPE_PRODUCTS.pod_4_base__au_king_upgrade,
			},
			caqueen: {
				name: 'CA Queen',
				// id: 40118736093272,
				id: STRIPE_PRODUCTS.pod_4_base__ca_queen_upgrade,
			},
			caking: {
				name: 'CA King',
				// id: 40118736158808,
				id: STRIPE_PRODUCTS.pod_4_base__ca_king_upgrade,
			},
			seemperor: {
				name: 'SE Emperor',
				// id: 40216811110488,
				id: STRIPE_PRODUCTS.pod_4_base__se_emperor_upgrade,
			},
			sesuperking: {
				name: 'SE Super King',
				// id: 40216811110488,
				id: STRIPE_PRODUCTS.pod_4_base__se_super_king_upgrade,
			},
			dkemperor: {
				name: 'DK Emperor',
				// id: 40216811110488,
				id: STRIPE_PRODUCTS.pod_4_base__dk_emperor_upgrade,
			},
			dksuperking: {
				name: 'DK Super King',
				// id: 40216811208792,
				id: STRIPE_PRODUCTS.pod_4_base__dk_super_king_upgrade,
			},
			aeking: {
				name: 'AE King',
				id: STRIPE_PRODUCTS.pod_4_base__ae__king_upgrade,
			},
			aeemperor: {
				name: 'AE Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__ae__emperor_upgrade,
			},
		},
	} as ProductCore,
	TheBaseNonMember: {
		handle: 'the-base-non-member',
		name: 'The Base',
		id: 'pod_4_base_upgrade_non_member',
		description: productDescriptions.theBaseDescription,
		variants: {
			queen: {
				name: 'Queen',
				id: STRIPE_PRODUCTS.pod_4_base__queen_upgrade_2024_non_member,
			},
			king: {
				name: 'King',
				id: STRIPE_PRODUCTS.pod_4_base__king_upgrade_2024_non_member,
			},
			caliking: {
				name: 'Cali King',
				id: STRIPE_PRODUCTS.pod_4_base__caliking_upgrade_2024_non_member,
			},
			caqueen: {
				name: 'CA Queen',
				id: STRIPE_PRODUCTS.pod_4_base__ca_queen_upgrade_2024_non_member,
			},
			caking: {
				name: 'CA King',
				id: STRIPE_PRODUCTS.pod_4_base__ca_king_upgrade_2024_non_member,
			},
			eusuperking: {
				name: 'EU Super King',
				id: STRIPE_PRODUCTS.pod_4_base__eu_super_king_upgrade_2024_non_member,
			},
			euemperor: {
				name: 'EU Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__eu_emperor_upgrade_2024_non_member,
			},
			uksuperking: {
				name: 'UK Super King',
				id: STRIPE_PRODUCTS.pod_4_base__uk_super_king_upgrade_2024_non_member,
			},
			ukemperor: {
				name: 'UK Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__uk_emperor_upgrade_2024_non_member,
			},
			auqueen: {
				name: 'AU Queen',
				id: STRIPE_PRODUCTS.pod_4_base__au_queen_upgrade_2024_non_member,
			},
			auking: {
				name: 'AU King',
				id: STRIPE_PRODUCTS.pod_4_base__au_king_upgrade_2024_non_member,
			},
			sesuperking: {
				name: 'SE Super King',
				id: STRIPE_PRODUCTS.pod_4_base__se_super_king_upgrade_non_member,
			},
			seemperor: {
				name: 'SE Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__se_emperor_upgrade_non_member,
			},
			dksuperking: {
				name: 'DK Super King',
				id: STRIPE_PRODUCTS.pod_4_base__dk_super_king_upgrade_non_member,
			},
			dkemperor: {
				name: 'DK Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__dk_emperor_upgrade_non_member,
			},
			aeking: {
				name: 'AE King',
				id: STRIPE_PRODUCTS.pod_4_base__ae__king_upgrade_non_member,
			},
			aeemperor: {
				name: 'AE Emperor',
				id: STRIPE_PRODUCTS.pod_4_base__ae__emperor_upgrade_non_member,
			},
		},
	} as ProductCore,
}
export const PillowProducts = {
	CarbonAirPillow: {
		handle: 'the-air-pillow',
		name: 'The Air Pillow',
		id: 'the_air_pillow',
		description: productDescriptions.carbonPillowDescription,
		variants: {
			onepack: {
				name: 'One Pack',
				// id: 32372077592664,
				id: STRIPE_PRODUCTS.the_air_pillow_queen__one_pack,
			},
			twopack: {
				name: 'Two Pack',
				// id: 32372077625432,
				id: STRIPE_PRODUCTS.the_air_pillow_queen__two_pack,
			},
		},
	} as ProductCore,
	CarbonAirPillowKing: {
		handle: 'the-air-pillow-king',
		name: 'The Air Pillow King',
		id: 'the_air_pillow',
		description: productDescriptions.carbonPillowDescription,
		variants: {
			onepack: {
				name: 'One Pack',
				// id: 33400684052568,
				id: STRIPE_PRODUCTS.the_air_pillow_king__one_pack,
			},
			twopack: {
				name: 'Two Pack',
				// id: 33400684085336,
				id: STRIPE_PRODUCTS.the_air_pillow_king__two_pack,
			},
		},
	} as ProductCore,
}

export const SheetProducts = {
	SheetSet: {
		handle: 'the-pod-sheet-set-eight',
		id: 'pod_sheet_set_white',
		name: 'Pod Sheet Set',
		description: productDescriptions.podSheetSetDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 33504296501336,
				id: STRIPE_PRODUCTS.pod_sheet_set_white__full,
			},
			queen: {
				name: 'Queen',
				// id: 33504296534104,
				id: STRIPE_PRODUCTS.pod_sheet_set_white__queen,
			},
			king: {
				name: 'King',
				// id: 33504296566872,
				id: STRIPE_PRODUCTS.pod_sheet_set_white__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 33504296599640,
				id: STRIPE_PRODUCTS.pod_sheet_set_white__cali_king,
			},
		},
	} as ProductCore,
	SheetSetGray: {
		handle: 'pod-sheet-set-dark-gray',
		id: 'pod_sheet_set_gray',
		name: 'Pod Sheet Set',
		description: productDescriptions.podSheetSetGrayDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 33330755141720,
				id: STRIPE_PRODUCTS.pod_sheet_set_gray__full,
			},
			queen: {
				name: 'Queen',
				// id: 33330755174488,
				id: STRIPE_PRODUCTS.pod_sheet_set_gray__queen,
			},
			king: {
				name: 'King',
				// id: 33330755207256,
				id: STRIPE_PRODUCTS.pod_sheet_set_gray__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 33330755240024,
				id: STRIPE_PRODUCTS.pod_sheet_set_gray__cali_king,
			},
		},
	} as ProductCore,
}

export const FoundationProducts = {
	SimpleFoundation: {
		handle: 'simple-foundation',
		name: 'Foundation',
		id: 'podfoundation',
		description: productDescriptions.podFoundationDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 43090462467,
				id: STRIPE_PRODUCTS.podfoundation__full,
			},
			queen: {
				name: 'Queen',
				// id: 43090462531,
				id: STRIPE_PRODUCTS.podfoundation__queen,
			},
			king: {
				name: 'King',
				// id: 43090462595,
				id: STRIPE_PRODUCTS.podfoundation__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 43090462659,
				id: STRIPE_PRODUCTS.podfoundation__cali_king,
			},
		},
	} as ProductCore,
}

export const CartridgeProducts = {
	Pod4Cartridge: {
		handle: 'pod4-insert',
		name: 'Pod 4 Insert',
		id: 'pod4cartridge',
		description: productDescriptions.pod4CartridgeDescription,
		variants: {
			us: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_us,
			},
			ca: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_ca,
			},
			eu: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_eu,
			},
			uk: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_uk,
			},
			au: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_au,
			},
			se: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_se,
			},
			dk: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.pod4_cartridge_dk,
			},
		},
	} as ProductCore,
}

export const BundleProducts = {
	// essentials bundle
	SleepEssentialBundle: {
		handle: 'the-sleep-essentials-bundle-eight',
		name: 'The Sleep Essentials Bundle',
		id: 'sleep_essentials_bundle_new',
		description: productDescriptions.sleepEssentialsDescription,
		variants: {
			full: {
				name: 'Full',
				// id: 33504484294744,
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_new__full,
			},
			queen: {
				name: 'Queen',
				// id: 33504484327512,
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_new__queen,
			},
			king: {
				name: 'King',
				// id: 33504484360280,
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_new__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 33504484393048,
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_new__cali_king,
			},
		},
	} as ProductCore,
	SleepEssentialBundleGray: {
		handle: 'the-sleep-essentials-bundle-gray',
		name: 'The Sleep Essentials Bundle (Gray)',
		id: 'sleep_essentials_bundle_gray',
		description: productDescriptions.sleepEssentialsDescription,
		variants: {
			full: {
				name: 'Full',
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_gray__full,
			},
			queen: {
				name: 'Queen',
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_gray__queen,
			},
			king: {
				name: 'King',
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_gray__king,
			},
			caliking: {
				name: 'Cali King',
				id: STRIPE_PRODUCTS.sleep_essentials_bundle_gray__cali_king,
			},
		},
	} as ProductCore,
}

export const BlanketProducts = {
	BrandedGravity: {
		handle: 'gravity-blanket-noeight',
		name: 'Gravity Blanket',
		description: productDescriptions.gravityBlanketDescription,
		id: 'gravity_blanket',
		variants: {
			standard: {
				name: 'Gravity Blanket',
				// id: 33593050759256,
				id: STRIPE_PRODUCTS.gravity_blanket__standard,
			},
		},
		cartDescription: ['Throw Size 72” x 48”. 15lb weight.'],
	} as ProductCore,
}

export const SprayProducts = {
	LavenderSpray: {
		handle: 'lavender-sleep-spray',
		name: 'Lavender Sleep Spray',
		id: 'lavender_sleep_spray',
		description: productDescriptions.lavenderSprayDescription,
		variants: {
			standard: {
				name: 'Lavender Sleep Spray',
				// id: 32841680781400,
				id: STRIPE_PRODUCTS.lavender_sleep_spray__2oz,
			},
		},
	} as ProductCore,
}

export const HypericeProducts = {
	HypervoltPlus: {
		handle: 'hypervolt-pro',
		name: 'Hypervolt 2 Pro',
		id: 'hypervolt_2_pro',
		description: productDescriptions.hypervoltProDescription,
		variants: {
			standard: {
				name: 'Hypervolt 2 Pro',
				// id: 32880117416024,
				id: STRIPE_PRODUCTS.hypervolt_2_pro__standard,
			},
		},
	} as ProductCore,
	VenomBack: {
		handle: 'eight-sleep-x-hyperice-venom-back',
		name: 'Venom Back',
		id: 'venom_back',
		description: productDescriptions.venomBackDescription,
		variants: {
			standard: {
				name: 'Venom Back',
				// id: 32929081294936,
				id: STRIPE_PRODUCTS.venom_back__standard,
			},
		},
	} as ProductCore,
}

export const NormaTecProducts = {
	LegPulse2: {
		handle: 'normatec-leg-pulse-2-0',
		name: 'Normatec 2.0',
		id: 'normatec_2',
		description: productDescriptions.legPulseDescription,
		variants: {
			standard: {
				name: 'Normatec 2.0',
				// id: 32929587134552,
				id: STRIPE_PRODUCTS.normatec_2__standard,
			},
		},
	} as ProductCore,
}

export const MembershipProducts = {
	EightsleepProMembership: {
		handle: '8-pro-membership',
		name: 'Pro Membership',
		id: 'membership_pro',
		description: [],
		variants: {
			standard: {
				name: 'Pro',
				// id: 40218251133016,
				id: STRIPE_PRODUCTS.membership_pro__yearly,
			},
		},
		sellingPlanId: 660471896,
		cartDescription: [
			'XX/mo (billed annually, plus applicable sales tax). Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, the vibration & thermal alarm, and sleep & health reports. Also includes a 2-year warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
	EightsleepPlusMembership: {
		handle: '8-plus-membership',
		name: 'Plus Membership',
		id: 'membership_plus',
		description: [],
		variants: {
			standard: {
				name: 'Plus',
				// id: 40218251165784,
				id: STRIPE_PRODUCTS.membership_plus__yearly,
			},
		},
		sellingPlanId: 660439128,
		cartDescription: [
			'XX/mo (billed annually, plus applicable sales tax). Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, a vibration & thermal alarm, and sleep & health reports. Also includes an extended warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
	AutopilotStandard: {
		handle: '8-pro-membership',
		name: 'Autopilot Standard',
		id: 'autopilot_standard',
		description: [],
		variants: {
			standard: {
				name: 'Standard',
				// id: 40976537616472,
				id: STRIPE_PRODUCTS.autopilot_standard__yearly,
			},
		},
		sellingPlanId: 819298392,
		cartDescription: [
			'XX/mo (billed annually, plus applicable sales tax). Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, the vibration & thermal alarm, and sleep & health reports. Also includes a 2-year warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
	AutopilotEnhanced: {
		handle: '8-plus-membership',
		name: 'Autopilot Enhanced',
		id: 'autopilot_enhanced',
		description: [],
		variants: {
			standard: {
				name: 'Enhanced',
				// id: 40976469688408,
				id: STRIPE_PRODUCTS.autopilot_enhanced__yearly,
			},
		},
		sellingPlanId: 819265624,
		cartDescription: [
			'XX/mo (billed annually, plus applicable sales tax). Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, a vibration & thermal alarm, and sleep & health reports. Also includes an extended warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
	AutopilotEnhancedOneTime: {
		handle: '8-plus-membership',
		name: 'Autopilot Enhanced',
		id: 'autopilot_enhanced__one_time',
		description: [],
		variants: {
			standard: {
				name: 'Enhanced',
				id: STRIPE_PRODUCTS.autopilot_enhanced__one_time,
			},
		},
		cartDescription: [
			'XX/mo. Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, a vibration & thermal alarm, and sleep & health reports. Also includes an extended warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
	AutopilotStandardOneTime: {
		handle: '8-plus-membership',
		name: 'Autopilot Enhanced',
		id: 'autopilot_standard__one_time',
		description: [],
		variants: {
			standard: {
				name: 'Standard',
				id: STRIPE_PRODUCTS.autopilot_standard__one_time,
			},
		},
		cartDescription: [
			'XX/mo. Includes two separate users per Pod. Unlocks the full intelligence of the Pod including automatic temperature adjustments, a vibration & thermal alarm, and sleep & health reports. Also includes an extended warranty (terms apply). Renews annually, cancel anytime.',
		],
	} as ProductCore,
}

export const WarrantyProducts = {}

export const MattressProducts = {
	FiveLayerDiscounted: {
		handle: 'pod-pro-max-mattress-only-1',
		name: '5-layer mattress',
		id: 'carpenter_five_layer_mattress',
		description: [
			'With our 5-layer mattress, you can achieve the perfect mix of temperature control and comfort. We’ve designed a breathable, supportive mattress to pair perfectly with the Pod Cover’s technology, so that you can create the ultimate sleep setup.',
		],
		variants: {
			full: {
				name: 'Full',
				// id: 39693227884632,
				id: STRIPE_PRODUCTS.carpenter_five_layer_mattress__full,
			},
			queen: {
				name: 'Queen',
				// id: 39693227917400,
				id: STRIPE_PRODUCTS.carpenter_five_layer_mattress__queen,
			},
			king: {
				name: 'King',
				// id: 39693227950168,
				id: STRIPE_PRODUCTS.carpenter_five_layer_mattress__king,
			},
			caliking: {
				name: 'Cali King',
				// id: 39693227982936,
				id: STRIPE_PRODUCTS.carpenter_five_layer_mattress__cali_king,
			},
		},
	} as ProductCore,
	ThreeLayer: {
		handle: 'canada-pod-pro-mattress-replacement',
		name: '3-layer mattress',
		id: 'carpenter_three_layer_mattress',
		description: ['Premium 3-layer mattress to compliment the Pod and upgrade your entire bedroom setup.'],
		variants: {
			cafull: {
				name: 'Full',
				// id: 39578425589848,
				id: STRIPE_PRODUCTS.carpenter_three_layer_mattress__ca_full,
			},
			caqueen: {
				name: 'Queen',
				// id: 39578425622616,
				id: STRIPE_PRODUCTS.carpenter_three_layer_mattress__ca_queen,
			},
			caking: {
				name: 'King',
				// id: 39578425655384,
				id: STRIPE_PRODUCTS.carpenter_three_layer_mattress__ca_king,
			},
		},
	} as ProductCore,
}
