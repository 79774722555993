import { MattressProducts } from 'products/allProducts'
import ShippingTimelines from '../shipping_timelines.json'
import { VariantCore } from 'products/types'
import { getMatchingUltraVariantForPod4 } from 'products/utils'
import { RegionShort } from 'utils/internationalization'

export interface IVariantShippingData {
	minDaysUS?: string
	maxDaysUS?: string
	minDaysCA?: string
	maxDaysCA?: string
	minDaysUK?: string
	maxDaysUK?: string
	minDaysEU?: string
	maxDaysEU?: string
	minDaysAU?: string
	maxDaysAU?: string
	minDaysAE?: string
	maxDaysAE?: string
	overrideString?: string
	isMembershipProduct?: boolean
	partnerPackaging?: boolean
}

export interface IShippingTimelines {
	[key: string]: IVariantShippingData
}

const shipping_timelines = ShippingTimelines as IShippingTimelines

export const moreThan3Weeks = (timeline: string) => {
	// really shoule be called isLongShippingTime
	if (!timeline) {
		return true
	}
	try {
		const time = timeline.match(/[0-9]-[0-9]*\s[\w]*/)![0]
		const saysWeeks = time.includes('weeks')
		if (saysWeeks) {
			return true
		} else {
			return false
		}
	} catch (e) {
		return true
	}
}

export interface ShippingStatus {
	text: string
	delayed?: boolean
	isMembershipProduct?: boolean
	doesNotShip?: boolean
}

function getDateISO(date) {
	return date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-')
}

function getDateStringToday() {
	return getDateStringTodayDelta()
}

function getDateStringTodayDelta(delta = 0) {
	try {
		const workingDate = new Date()
		workingDate.setDate(workingDate.getDate() + delta)
		return getDateISO(workingDate)
	} catch (error) {
		console.log('getDateStringTodayDelta', error)
	}
	return getDateISO(new Date())
}

function addBusinessDay(date, numDays) {
	try {
		if (!numDays || numDays < 1) {
			return date
		}
		const isSaturday = date.getDay() === 6
		const isFriday = date.getDay() === 5

		if (isFriday) {
			date.setDate(date.getDate() + 3)
		} else if (isSaturday) {
			date.setDate(date.getDate() + 2)
		} else {
			date.setDate(date.getDate() + 1)
		}
		return addBusinessDay(date, numDays - 1)
	} catch (error) {
		console.log('addBusinessDay', error)
	}
	return date
}

export function getBusinessDateFromToday(delta = 0) {
	if (delta < 0) {
		throw new Error('Negative business days not supported')
	}
	const workingDate = new Date()
	const businessDay = addBusinessDay(workingDate, delta)
	return businessDay
}

export const getShippingTimelinePodVariantId = (variantObject: VariantCore, eight_mattress?: boolean, base_upgrade?: boolean) => {
	const isMattress = eight_mattress || variantObject.id === MattressProducts.FiveLayerDiscounted.variants.queen.id
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		if (document.cookie.includes('shippingTimelinesAB.1')) {
			return base_upgrade ? getMatchingUltraVariantForPod4(variantObject) : variantObject.id
		}
	}
	return isMattress ? `pod_3_max_${variantObject.key}` : base_upgrade ? getMatchingUltraVariantForPod4(variantObject) : variantObject.id
}

export const getShippingTimelineVariantData = (variantObject: VariantCore, eight_mattress?: boolean, base_upgrade?: boolean) => {
	const podVariantId = getShippingTimelinePodVariantId(variantObject, eight_mattress, base_upgrade)
	return shipping_timelines[podVariantId]
}

export const getShippingText = (
	variantObject: VariantCore,
	currentRegion: RegionShort,
	eight_mattress?: 'yes_mattress' | 'no_mattress' | undefined,
	base_upgrade?: 'standard' | 'none' | undefined
) => {
	const mattressOverride = eight_mattress === 'yes_mattress' && (currentRegion === '' || currentRegion === 'us')
	const podVariantId = getShippingTimelinePodVariantId(variantObject, mattressOverride, base_upgrade === 'standard')
	const variantData = shipping_timelines[podVariantId]

	const shippingPageText = getShopPageShippingText(
		variantData,
		currentRegion === 'ca',
		currentRegion === 'uk',
		currentRegion === 'eu' || currentRegion === 'se' || currentRegion === 'dk',
		currentRegion === 'au',
		currentRegion === 'ae'
	)

	return shippingPageText.text || ''
}

export const getShopPageShippingText = (
	variantData: IVariantShippingData,
	isCanada: boolean = false,
	isUK: boolean = false,
	isEU: boolean = false,
	isAU: boolean = false,
	isAE: boolean = false
): ShippingStatus => {
	if (!variantData) {
		return {
			text: '',
			delayed: true,
		}
	}

	let shippingText = ''
	let delayed = false
	let isMembershipProduct = variantData.isMembershipProduct

	if (variantData.overrideString) {
		shippingText = variantData.overrideString
	} else {
		if (isCanada) {
			if (!variantData.minDaysCA || !variantData.maxDaysCA) {
				return {
					text: 'Does not ship to Canada',
					delayed: true,
					doesNotShip: true,
				}
			}
		}

		if (isUK) {
			if (!variantData.minDaysUK || !variantData.maxDaysUK) {
				return {
					text: 'Does not ship to the United Kingdom',
					delayed: true,
					doesNotShip: true,
				}
			}
		}

		if (isEU) {
			if (!variantData.minDaysEU || !variantData.maxDaysEU) {
				return {
					text: 'Does not ship to Europe',
					delayed: true,
					doesNotShip: true,
				}
			}
		}

		if (isAU) {
			if (!variantData.minDaysAU || !variantData.maxDaysAU) {
				return {
					text: 'Does not ship to Australia',
					delayed: true,
					doesNotShip: true,
				}
			}
		}

		if (isAE) {
			if (!variantData.minDaysAE || !variantData.maxDaysAE) {
				return {
					text: 'Does not ship to United Arab Emirates',
					delayed: true,
					doesNotShip: true,
				}
			}
		}

		let minDays = Number(variantData.minDaysUS)
		let maxDays = Number(variantData.maxDaysUS)

		if (isCanada) {
			minDays = Number(variantData.minDaysCA!)
			maxDays = Number(variantData.maxDaysCA!)
		} else if (isUK) {
			minDays = Number(variantData.minDaysUK!)
			maxDays = Number(variantData.maxDaysUK!)
		} else if (isEU) {
			minDays = Number(variantData.minDaysEU!)
			maxDays = Number(variantData.maxDaysEU!)
		} else if (isAU) {
			minDays = Number(variantData.minDaysAU!)
			maxDays = Number(variantData.maxDaysAU!)
		} else if (isAE) {
			minDays = Number(variantData.minDaysAE!)
			maxDays = Number(variantData.maxDaysAE!)
		}

		// if (maxDays < 10) {
		// 	maxDays = 10
		// }

		let minTargetDate = getBusinessDateFromToday(minDays)
		let maxTargetDate = getBusinessDateFromToday(maxDays)
		const deliveryDate = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(maxTargetDate)

		const dateRangeString =
			new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(minTargetDate) + ' - ' + new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(maxTargetDate)

		delayed = Number(variantData.minDaysUS) > 13 && !variantData.partnerPackaging
		if (delayed) {
			shippingText = 'Due to high demand, ships ' + dateRangeString
		} else if (typeof window !== 'undefined' && sessionStorage.getItem('shippingcutofftwo')) {
			shippingText = 'Order by Dec 18 for Holiday delivery'
		} else {
			// shippingText = 'Order by Dec 13 for Christmas delivery' // + deliveryDate
			shippingText = 'Ships ' + dateRangeString
		}

		if (variantData.partnerPackaging) {
			shippingText += ' in partner packaging'
		}
	}

	return {
		text: shippingText,
		delayed,
		isMembershipProduct,
	}
}

function getPostPurchaseShippingText(variantData: any) {
	if (!variantData) {
		return ''
	}
	if (!variantData.minDays || !variantData.maxDays) {
		return ''
	}

	let minDays = Number(variantData.minDays)
	let maxDays = Number(variantData.maxDays)

	// if (maxDays < 10) {
	// 	maxDays = 10 // this was to manually give us a buffer when saying "delivered by"
	// }

	var minTargetDate = getBusinessDateFromToday(minDays)
	var maxTargetDate = getBusinessDateFromToday(maxDays)

	const dateRangeString =
		new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(minTargetDate) + ' - ' + new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(maxTargetDate)

	const delayed = variantData.minDays > 13
	let shippingText = 'Ships ' + dateRangeString

	const deliveryDate = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(maxTargetDate)

	if (variantData.partnerPackaging) {
		shippingText += ' in partner packaging.'
	} else {
		shippingText += '.'
	}
	return shippingText
}
function getST3Format(variantData: any) {
	if (!variantData) {
		return ''
	}
	if (!variantData.minDays || !variantData.maxDays) {
		return ''
	}

	let minDays = Number(variantData.minDays)
	let maxDays = Number(variantData.maxDays)

	// if (maxDays < 10) {
	// 	maxDays = 10 // this was to manually give us a buffer when saying "delivered by"
	// }

	var minTargetDate = getBusinessDateFromToday(minDays)
	var maxTargetDate = getBusinessDateFromToday(maxDays)

	const dateRangeString =
		new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(minTargetDate) + ' - ' + new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(maxTargetDate)

	const delayed = variantData.minDays > 13
	let shippingText = dateRangeString
	return shippingText
}

export function getAllShippingTexts(variantData: IVariantShippingData) {
	if (!variantData) {
		return null
	}
	const countryCodes = Object.keys(variantData)
		.filter((s) => s.split('minDays') && s.split('minDays').length > 1)
		.map((x) => x.split('minDays')[1])
	const result: any = {}

	countryCodes.forEach((cc) => {
		const text = getPostPurchaseShippingText({
			minDays: (variantData as any)['minDays' + cc],
			maxDays: (variantData as any)['maxDays' + cc],
		})
		if (text) {
			result[cc] = text
		}
	})

	return result
}
export function getST3Text(variantData: IVariantShippingData) {
	if (!variantData) {
		return null
	}
	const countryCodes = Object.keys(variantData)
		.filter((s) => s.split('minDays') && s.split('minDays').length > 1)
		.map((x) => x.split('minDays')[1])
	const result: any = {}

	countryCodes.forEach((cc) => {
		const text = getST3Format({
			minDays: (variantData as any)['minDays' + cc],
			maxDays: (variantData as any)['maxDays' + cc],
		})
		if (text) {
			result[cc] = text
		}
	})

	return result
}

export const shipsToCanada = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysCA || variantData.maxDaysCA
}

export const shipsToUK = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysUK || variantData.maxDaysUK
}

export const shipsToEU = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysEU || variantData.maxDaysEU
}

export const shipsToAU = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysAU || variantData.maxDaysAU
}

export const shipsToAE = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysAE || variantData.maxDaysAE
}

export const shipsToUS = (variantData: IVariantShippingData) => {
	if (!variantData) {
		return false
	}
	return variantData.minDaysUS || variantData.maxDaysUS
}

export default shipping_timelines
