import styles from './Hero.module.scss'
import { HeroProps } from './Hero.types'

import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { BasicHero, BasicHeroProps } from '../BasicHero'
import { HeroABJan15 } from 'ab_testing'
import useABTestVariant from 'hooks/useABTestVariant'

export const HeroConnected = observer((props: Partial<HeroProps>) => {
	const { settingsStore } = useRootStore()

	const isHeroABJan15 = useABTestVariant(HeroABJan15, 1)

	const eyebrow = ''
	const headline = isHeroABJan15 ? 'Waking up never felt so good' : `Nights that fuel your best days`
	const subheadline = isHeroABJan15 ? 'Turn any bed into the ultimate sleeping experience' : `Turn any bed into the ultimate sleeping experience`
	const cta = {
		text: 'Shop now',
		href: '/product/pod-cover',
	}

	if (settingsStore.currentRegion === 'ae') {
		return (
			<BasicHero
				eyebrow={eyebrow}
				headline={headline}
				subheadline={subheadline}
				cta={cta}
				media={{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/UAE-Header+1.jpg',
						mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/UAE-Header+1+(1).jpg',
						alt: 'A modern bedroom with the Burj Khalifa in the background',
					},
				}}
				{...props}
			/>
		)
	}

	const defaultMedia: BasicHeroProps['media'] = {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hero_post_boxing_day.png',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hero_post_boxing_day_mobile.jpg',
			alt: 'A cool bedroom with the Pod 4 by the bedside',
			doNotCompress: true,
		},
	}

	const janTestMedia: BasicHeroProps['media'] = {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hero_header_desktop_2025-01-15.jpg',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hero_header_mobile_2025-01-15_v2.jpg',
			alt: '',
			doNotCompress: true,
		},
		className: styles.jan_image,
	}

	return (
		<BasicHero
			eyebrow={eyebrow}
			headline={headline}
			subheadline={subheadline}
			cta={cta}
			media={isHeroABJan15 ? janTestMedia : defaultMedia}
			leftAlign={isHeroABJan15}
			{...props}
		/>
	)
})
