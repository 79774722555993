import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './ImageCarousel.module.scss'
import { ImageCarouselHandle, ImageCarouselProps } from './ImageCarousel.types'
import { useSwipe } from 'components/_hooks/useSwipe'
import { clamp } from 'components/_utils/mathUtils'
import { amSwipeImageCarousel } from 'events/amplitude'
import { MediaRendered } from './subcomponents/MediaRendered'
import { NavigationButtons } from './subcomponents/NavigationButtons'
import { Thumbnails } from './subcomponents/Thumbnails'

export const ImageCarousel = forwardRef<ImageCarouselHandle, ImageCarouselProps>((props, ref) => {
	const { items, useDots = false } = props

	const sliderRef = useRef<HTMLUListElement>(null)

	const [currentIndex, setCurrentIndex] = useState(0)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			const length = items.length
			amSwipeImageCarousel(direction, props.id)
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500,
		[items.length]
	)

	useImperativeHandle(ref, () => ({
		goToSlide: (index: number) => {
			setCurrentIndex(index)
		},
	}))

	useEffect(() => {
		setCurrentIndex(0)
	}, [props.id, items.length])

	const clampedIndex = clamp(currentIndex, 0, items.length - 1)

	return (
		<div className={styles.container}>
			<ul
				className={styles.media_stack}
				ref={sliderRef}
			>
				{items.map((item, index) => (
					<li
						key={`${index}`}
						className={styles.media_item}
						style={{
							transform: `translateX(calc(${(index - clampedIndex) * 100.1}%))`,
						}}
					>
						<MediaRendered
							{...item}
							active={clampedIndex === index}
						/>
					</li>
				))}

				<NavigationButtons
					items={items}
					currentIndex={clampedIndex}
					setCurrentIndex={setCurrentIndex}
				/>
			</ul>

			<Thumbnails
				items={items}
				currentIndex={clampedIndex}
				setCurrentIndex={setCurrentIndex}
				useDots={useDots}
			/>
		</div>
	)
})
